import React, { useState } from 'react'
import styled from 'styled-components'
// import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
// import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import { FaUserCheck } from "react-icons/fa"
import { FaChevronRight } from "react-icons/fa"
import { BsFillFileCheckFill } from "react-icons/bs"
import loadable from '@loadable/component'

import addQuery from '../../utils/addQuery'
import ContentContainer from '../UI/ContentContainer'
import { BUTTON_COLOR, BUTTON_SIZE, URL } from '../../support'
import HomeButton from '../UI/Buttons/HomeButton';
// import MmeModal from '../UI/Modal'

const MmeModal = loadable(() => import('../UI/Modal'))
const HomePersonalLoansMadeEasy = ({ ai = false }) => {
	const [open, setOpen] = useState(false)
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

	/*
	** Please do not remove the below part which is initially for fetching data from Contentful
	*/

	// const data = useStaticQuery(graphql`
	// 	query HomePageAwardListQuery {
	// 		contentfulComponentHomePagePersonalLoansMadeEasyAwardList {
	// 			img {
	// 				gatsbyImageData(placeholder:NONE width:80)
	// 				title
	// 				description
	// 			}
	// 		}
	// 	}
	// `)

	// const getItems = (data) => {
	// 	const itemsArray = []
	// 	data.contentfulComponentHomePagePersonalLoansMadeEasyAwardList.img.forEach((item, index) => {
	// 		itemsArray.push(
	// 			<Item key={index} href={item.description || null}>
	// 				<GatsbyImage image={item?.gatsbyImageData} alt={item.title} width={80} height={80} />
	// 			</Item>
	// 		)
	// 	})
	// 	return itemsArray
	// }


	const imageWidth = 500
	return (
		<ContentContainer>
			<Wrapper>
				<Main>
					<MainGetRate>
						<h1>Personal loans <br /><span className="greenHeader">made easy</span></h1>
						
						<div className="max-width-450">
							<div>Comparison rates from{" "}
								<a className="rate-star-link" href="#fine-print"><strong>8.27% p.a.*</strong></a>
							</div>
						</div>

						<div className="btnWrapper">
							<HomeButton
								height={BUTTON_SIZE.HEIGHT_L}
								padding={BUTTON_SIZE.PADDING_L}
								fontSize={BUTTON_SIZE.FONT_SIZE_L}
								background={BUTTON_COLOR.GREEN}
								backgroundHover={BUTTON_COLOR.GREEN_HOVER}
								color={BUTTON_COLOR.TEXT_COLOR_WHITE}
								text='Get My Rate'
								handleClick={() => addQuery(URL.QUOTE)}
							/>
							<GetScore onClick={handleClickOpen} >
								<div className="sm-button">Get My Credit Score</div>
								<div className="icon">
									<FaChevronRight />
								</div>
							</GetScore>
							<MmeModal open={open} handleClose={handleClose} />
						</div>

						<Question href={`/personal-loans#FAQs`}>
							<div className="icon">
								<FaUserCheck />
							</div>
							<div>Am I eligible?</div>
							{/* <div className="icon">
								<FaChevronRight />
							</div> */}
						</Question>
						<Question href={URL.RESUME}>
							<div className="icon">
								<BsFillFileCheckFill />
							</div>
							<div>Resume my application</div>
							{/* <div className="icon">
								<FaChevronRight />
							</div> */}
						</Question>

					</MainGetRate>
					<MainImg>
						{!ai ? 
							<StaticImage src='../../assets/images/man-phone.png' alt="manWithPhone" placeholder='none' loading='eager' width={imageWidth} height={imageWidth}/>
						:
							<StaticImage src='../../assets/images/ai-man-phone.png' alt="manWithPhone" placeholder='none' loading='eager' width={imageWidth} height={imageWidth} imgStyle={{borderRadius: "50%"}} formats={["png"]} />
						}
					</MainImg>
				</Main>

				<AwardList>
					<StaticImage src='../../assets/images/homeAwardList.png' alt="homeAwardList" placeholder='none' loading='eager' />
					{/* {getItems(data)} */}
				</AwardList>
			</Wrapper>
		</ContentContainer>
	)
}

const Wrapper = styled.div`
	position: relative;
	z-index: 1;
	margin: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	@media screen and (max-width: 768px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
`
const Main = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 16px;
	width: 100%;
	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr;
	}

`
const MainGetRate = styled.div`
	@media screen and (max-width: 768px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.sm-button {
		font-family: PoppinsBold;
	}
	.rate-star-link {
		&:hover {
			color: inherit;
		}
	}
	h1 {
		margin: 10px 0;
		@media screen and (max-width: 991px) {
			font-size: 42px;
		}
		@media screen and (max-width: 768px) {
			max-width: 400px;
			text-align: center;
			margin-right: auto;
			margin-left: auto;
		}
	};
	.greenHeader {
		color: rgb(29 188 110);
	};
	.max-width-450 {
		max-width: 450px;
		@media screen and (max-width: 768px) {
			margin-right: auto;
			margin-left: auto;
		}
		>div {
			margin: 20px 0;
			font-size: 20px;
			text-align: left;
			@media screen and (max-width: 768px) {
				text-align: center;
			}
		}
	}
	.btnWrapper {
		padding: 40px 0;
		display: flex;
		align-items: center;
		button {
			margin: 0;
		}
		@media screen and (max-width: 991px) {
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			padding: 0px 0 20px 0; 
		}
		@media screen and (max-width: 768px) {
			align-items: center;
		}
	}
`

const GetScore = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;
	margin-left: 30px;
	p {
		font-family: QuicksandBold;
		margin: 5px 0;
		font-size: 20px;
	};
	.icon {
		padding: 0 10px; 
		display: flex; 
		align-items: center;
	};
	@media screen and (max-width: 991px) {
		margin: 20px 0 0 0;
	}
	@media screen and (max-width: 767px) {
		p {
			font-size: 15px;
		}
	}
`
const Question = styled.a`
	display: flex;
	align-items: center;
	div {
		margin: 5px 0;
	};
	.icon {
		padding: 0 10px; 
		display: flex; 
		align-items: center;
	};

	@media screen and (max-width: 768px) {
		justify-content: center;
	}

`
const MainImg = styled.div`
	justify-self: center;
	@media screen and (max-width: 768px) {
		margin: 50px 0 30px 0;
		width: 60vw;
	};
	@media screen and (max-width: 479px) {
		width: 80vw;
	}
`
const AwardList = styled.div`
	margin-top: 40px;
	${'' /* display: flex;
	justify-content: flex-start; */}
	width: 500px;
	@media screen and (max-width: 991px) {
		width: 440px;
	};
	@media screen and (max-width: 768px) {
		width: 100%;
	};

`
// const Item = styled.a`
// 	cursor: pointer;
// 	margin-right: 30px;
// 	height: 86px;
// 	width: 86px;
// 	@media screen and (max-width: 991px) {
// 		width: 18vw;
// 	};
// 	@media screen and (max-width: 768px) {
// 		margin: 0 auto;
// 	};

// `

export default HomePersonalLoansMadeEasy